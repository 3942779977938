
//@ts-ignore
import * as pdfjsLib from "pdfjs-dist/build/pdf";
//@ts-ignore
import { PDFViewer } from "pdfjs-dist/web/pdf_viewer";
import "pdfjs-dist/web/pdf_viewer.css";
import { Options, Vue } from "vue-property-decorator";
//@ts-ignore
import PdfWorker from "pdfjs-dist/build/pdf.worker.js";

pdfjsLib.GlobalWorkerOptions.workerPort = new PdfWorker();

@Options({
  props: {
    url: {
      type: String,
    },
  },
})
export default class PdfView extends Vue {
  public name = "PdfView";

  async getPdf() {
    let container = document.getElementById("pageContainer");
    let pdfViewer = new PDFViewer({
      container: container,
    });
    //@ts-ignore
    let loadingTask = pdfjsLib.getDocument(this.url);
    let pdf = await loadingTask.promise;
    //@ts-ignore
    pdfViewer.setDocument(pdf);
  }

  mounted() {
    //@ts-ignore
    this.getPdf();
  }
}
